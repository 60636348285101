import Vue from 'vue';
import Router from 'vue-router';

let Frontend = () => import('@pages/User/Frontend/index.vue');
let Backend = () => import('@pages/User/Backend/index.vue');
let Statistics = () => import('@pages/Statistics/index.vue');
let Log = () => import('@pages/Log/index.vue');
let Grab = () => import('@pages/Grab/index.vue');
let Search = () => import('@pages/Search/index.vue');
let Video = () => import('@pages/Video/index.vue');
let Login = () => import('@pages/Login/index.vue');

Vue.use(Router);
export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: {
        name: 'Frontend'
      }
    },
    {
      path: 'login',
      name: 'Login', 
      component: Login
    },
    {
      path: '/user/frontend',
      name: 'Frontend',
      component: Frontend,
    },
    {
      path: '/user/backend',
      name: 'Backend',
      component: Backend,
    },
    {
      path: '/statistics',
      name: 'Statistics',
      component: Statistics,
    },
    {
      path: '/log',
      name: 'Log',
      component: Log,
    },
    {
      path: '/grab',
      name: 'Grab',
      component: Grab,
    },
    {
      path: '/search',
      name: 'Search',
      component: Search,
    },
    {
      path: '/video',
      name: 'Video',
      component: Video,
    }
  ]
});