<template lang="pug" >
  .header-wrap
    el-menu.el-menu-demo(
      :default-active='activeIndex' 
      mode='horizontal' 
      @select='handleSelect' 
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
    )
      el-menu-item(index='system') 系统设置
      el-menu-item(index='video') 视频管理
      span.roles
        template(v-if="!isLogin")
          el-button(type="text") 登录
        template(v-else)
          span {{userinfo.username || ''}}
          span.interval | 
          el-button.grey(type="text", @click="logout") 登出
    //- login(
    //-   :isShow="isShowLoginDia",
    //-   @close-dia="closeDia", 
    //-   @get-userinfo="getUserData"
    //- )
      
</template>
<script>
import LoginLayer from './LoginLayer.vue'
// import Http from '@/api/userInfo';
import HTTPLogin from '@/api/login'
// import qs from 'qs';
import Vm from '@/utils/eventBus'

export default {
  name: 'Header',

  components: { LoginLayer },

  props: {
    isLogin: {
      type: Boolean,
      default: false
    }, 

    userinfo: {
      type: Object,
      default:function(){return {}}
    }

  },

  data() {
    return {
      isShowLoginDia: false,
      userName: '',
      activeIndex: 'system'
    };
  },

  created() {
    // this.getUserData();
    Vm.$on('show-login', this.showLogin);
  },

  methods: {
    handleSelect(val) {
      let routeName = val === 'video' ? 'Video' : 'Frontend'
      this.$router.push({name: routeName});
      this.$emit('display-type', val)
    },

    showLogin() {
      this.isShowLoginDia = true;
    },

    closeDia() {
      this.isShowLoginDia = false;
    },

    logout() {
      HTTPLogin.logout().then((res) => {
        if (res.code === 0) {
          this.$message({
            type: 'success',
            message: '退出成功',
            duration: '500',
            onClose() {
              window.location.reload();
            }
          });
        }
      });
    },

    /* getUserData() {
      Http.getData({needLogin: false}).then(res => {
        this.loading = false;
        if (res.code === 0) {
          
          let data = res.body || {};
          this.isLogin = true;
          this.userName = data.user_info.username || '';
          window.localStorage.setItem('M_STORAGE_USER_INFO', qs.stringify(data.user_info))
        } else {
          this.isLogin = false;
        }
      }, () => {
        this.loading = false;
        this.isLogin = false;
      });
    }, */
  },

  watch: {
    '$route.name': function(val) {
      this.activeIndex = val === 'Video' ? 'video' : 'system';
    } 
  }
}
</script>
<style lang="scss" scoped >
  .header-wrap {
    width: 100%;
  }
  .el-menu-demo {
    width: 100%;
    
    .roles {
      float: right;
      margin-right: 20px;
      color: #FFF;
      font-size: 14px;
    }
    .interval {
      margin: 0 6px;
      display: inline-block;
    }
    .grey {
      color: #999;
    }
  }
</style>
<style lang="scss">
  .el-menu-demo {
    .el-button {
      font-size: 14px;
      color: #FFF;
      &.grey {
        background-color: transparent;
      }
    }
  }
</style>