<template lang="pug" >
  .login-wrap
    el-dialog(
      title="登录",
      width="428px",
      :visible.sync="isShowLayer",
      append-to-body
      custom-class="login-dia",
      @close="handleClose",
      :close-on-click-modal="false",
      :modal="false",
    )
      .login-content-wrap
        .content
          el-form(label-position="top")
            el-form-item(label="用户名")
              el-input(v-model="username", size="large", @input="changeUser", placeholder="用户名")
            el-form-item(label="密码")
              el-input(v-model="password", size="large" show-password, @change="changePwd", placeholder="密码")
          .space-between
            el-checkbox(v-model="isRemembered", @input="rememberPwd") 记住密码
            //- el-button.forget-pwd(type="text", @change="forgetPwd") 忘记密码?
            //- el-button.forget-pwd(type="text", @click="showRegister") 立即注册
          .login-btn-wrap
            el-button.login-btn(size="large", type="primary", @click="login") 立即登录
          //- .space-between.tail
          //-   span 您还没有账号？
            //- el-button.forget-pwd(type="text", @click="showRegister") 注册

</template>
<script >
import Http from '@/api/login'
import Vm from '@/utils/eventBus'

const M_IS_REMEMBER_PWE = 'M_IS_REMEMBER_PWE';
const M_STORAGE_LOGIN_USER_KEY = 'M_STORAGE_LOGIN_USER_KEY';
const M_STORAGE_LOGIN_USER_PWD = 'M_STORAGE_LOGIN_USER_PWD';

export default {
  name: 'LoginLayer',
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      username: '',
      password: '',
      isRemembered: localStorage.getItem(M_IS_REMEMBER_PWE) == 1,
      isShowLayer: this.isShow,
    };
  },

  mounted() {
    if (localStorage.getItem(M_IS_REMEMBER_PWE) == 1) {
      this.username = localStorage.getItem(M_STORAGE_LOGIN_USER_KEY) || '';
      this.password =  localStorage.getItem(M_STORAGE_LOGIN_USER_PWD) || '';
    }
  },

  methods: {
    forgetPwd() {

    },

    showRegister() {
      this.$emit('show-register');
      this.$nextTick(() => {
        this.isShowLayer = false;
      })
    },

    rememberPwd() {
      if (this.isRemembered) {
        localStorage.setItem(M_IS_REMEMBER_PWE, 1);
        localStorage.setItem(M_STORAGE_LOGIN_USER_KEY, this.username);
        localStorage.setItem(M_STORAGE_LOGIN_USER_PWD, this.password);
      } else {
        localStorage.setItem(M_IS_REMEMBER_PWE, 0);
        localStorage.setItem(M_STORAGE_LOGIN_USER_KEY, '');
        localStorage.setItem(M_STORAGE_LOGIN_USER_PWD, '');
      }
    },

    changeUser() {
      this.rememberPwd();
    }, 

    changePwd() {
      this.rememberPwd()
    },

    login() {
      let params = {
        username: this.username,
        password: this.password,
        token: '',
      };
      Http.login(params).then(res => {
        if (res.code === 0) {
          this.isShowLayer = false;
          
          this.$emit('get-userinfo');
          Vm.$emit('login');
          this.$message({
            type: 'success',
            message: '登录成功！'
          });
          window.location.href = '/user/frontend';

        }
      });
    },

    handleClose() {
      this.$emit('close-dia')
    },
  },

  watch: {
    isShow(val) {
      this.isShowLayer = val;
    }
  }
}
</script>
<style lang="scss" scoped >
  .login-content-wrap .content {
    margin: 0 auto;
    padding: 20px;
    .forget-pwd {
      font-size: 12px;
      font-weight: 400;
    }
    .login-btn-wrap {
      text-align: center;
      .login-btn {
        margin-top: 20px;
        width: 100%;
      }
    }
    .tail {
      width: 60%;
      margin: 0 auto;
      font-size: 12px;
      font-weight: 400;
    }
  }
</style>
<style lang="scss">
  .login-dia {
    .el-dialog__header {
      text-align: center;
      font-size: 18px;
      font-weight: 700;
    }
    .el-dialog__headerbtn .el-dialog__close {
      display: none;
    }
  }
  .login-dia .login-content-wrap .content {
    .el-input {
      width: 100%;
    }
    .el-form--label-top .el-form-item__label {
      padding: 0;
    }
    .el-checkbox__label {
      font-weight: 400;
      font-size: 12px;
    }
    
    .el-button--large {
      padding: 12px 50px;
      width: 100%;
      background-color: #E30000;
      border-color: #E30000;
    }
    
  }
</style>
