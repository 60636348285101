/*
 * axios 封装
 * 请求拦截、响应拦截、错误统一处理
 */

import axios from 'axios';
// import Vue from 'vue';
import Vm from '@/utils/eventBus'
// import qs from 'qs';

const isMock = /^mock\./.test(window.location.host);
// const vm = new Vue();

const errHandle = (status, other) => {
    // todo...
    switch (status) {
        case 404:
            // 找不到资源
            break;
        default:
            console.log(other);
    }
};

// 创建axios实例
let instance = axios.create({
    timeout: 10 * 1000
});

let checkNeedLogin = (config = {}) => {
    if (config.method === 'get') {
        return config?.params?.needLogin === false ? false : true;
    } 
    return config.needLogin === false ? false : true;
};


// 设置post的http请求头
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
instance.defaults['withCredentials'] = !isMock;

/*
 * 请求拦截器
 */
instance.interceptors.request.use(

    (config) => {
        // todo...
        // const token = store.state.token;
        // token && (config.headers.Authorization = token);
        
       return config;
    },
    error => Promise.reject(error)
);

/*
 * 响应拦截器
 */
instance.interceptors.response.use(
    // success
    (res) => {
        let { config, data } = res;
        let needLogin = checkNeedLogin(config);
        // console.log('res', config, data)
        if(res.status === 200){
            if (data.code !== 0) {
                
                needLogin && Vm.$message({
                    type: 'warning',
                    message: data.message || '服务器繁忙，请稍后再试',
                    duration: 1000
                });
                if(data && data.code === 4001){
                    Vm.$emit('show-login');
                }
                return Promise.resolve(data)
            } else {
                return Promise.resolve(data)
            }
        } else {
          return Promise.reject(data);
        }
    },
    error => {
        const { response } = error;
        if (response) {
            // status 不在2xx范围内
            errHandle(response.status, response.data.desc);
        } else {
            // 断网情况的处理
            // dialog提示
        }
    }
);

export default instance;
