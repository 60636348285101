import Http from '@/utils/http';
import qs from 'qs';

export default {
    login(params, option) {
        return Http.post(`/apim/login`, qs.stringify(params) , option);
    },

    // register(params, option) {
    //     return Http.post(`/api/reg`, qs.stringify(params) , option);
    // },
    
    logout(params, option) {
        return Http.post(`/apim/logout`, qs.stringify(params), option)
    }
};