import Vue from 'vue';

Vue.mixin({
  methods: {
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 0) {
        return 'grey'
      }
    },
  },
})