<template lang="pug" >
  el-menu.el-menu-vertical(
    :default-active='defaultMenu' 
    @open='handleOpen' 
    @close='handleClose'
    router
    ref="elMenu"
  )
    template(v-for="item in menuList")
      el-submenu(
        v-if="item.child && item.child.length > 0", 
        :index="item.value"
      )
        template(slot="title")
          i.el-icon-location
          span {{item.name}}
        el-menu-item(
          v-for="subItem in item.child",
          :key="subItem.value", 
          :index="subItem.route",
        )
          i.el-icon-document
          span {{subItem.name}}
      el-menu-item(
        v-else,
        :key="item.value", 
        :index="item.route"
      )
        i.el-icon-setting
        span(slot="title") {{item.name}}
</template>
<script>
export default {
  name: 'Sidebar',
  data() {
    return {
      system: [
        {
          name: '用户管理',
          value: '用户管理',
          child: [
            {
              name: '前端用户',
              value: '/user/frontend',
              route: '/user/frontend'
            },
            {
              name: '后台账号',
              value: '/user/backend',
              route: '/user/backend'
            },
            // {
            //   name: '角色管理',
            //   value: '角色管理',
            //   route: '/role'
            // }
          ]
        },
        {
          name: '统计',
          value: '/statistics',
          route: '/statistics'
        },
        {
          name: '日志',
          value: '/log',
          route: '/log'
        },
        {
          name: '抓取设置',
          value: '/grab',
          route: '/grab'
        },
        // {
        //   name: '搜索设置',
        //   value: '/search',
        //   route: '/search'
        // }
      ],
      video: [
        {
          name: '视频管理',
          value: '视频管理',
          child: [
            {
              name: '视频列表',
              value: '/video',
              route: '/video'
            }
          ]
        },
      ]
    };
  },

  props: {
    menuType: {
      type: String,
      default: ''
    }
  },

  computed: {
    menuList() {
      return this[this.menuType];
    },

    defaultMenu() {
      // console.log('===55:', this.menuType)
      let routePath = this.$route.path;
      // console.log('routePath===', routePath);
      return this.menuType === 'video' ? '/video' : routePath;
    }
  },

  methods: {
    handleOpen() {

    },

    handleClose() {

    },

  },

  // watch: {
  //   ['$route.name'](val) {
  //     console.log('val7777:', val, this.$refs.elMenu)
  //     let index = val === 'Video' ? '/video' : '/user/frontend';
  //     this.defaultMenu = '/search'
  //     console.log('index:', index)
  //   }
  // }
}
</script>
<style lang="scss" scoped >
  .el-menu-vertical {
    border: 0;
  }
</style>