<template lang="pug">
  #app
    el-container(id="container")
      .container-inner
        router-view(:config-all="configAll", :is-login="isLogin", v-if="!isLogin")
        template(v-else)
          el-header.header-wrap
            .site-title 视频管理
            .header-out
              Header(@display-type="displayType", :is-login="isLogin", :userinfo="userinfo")
          el-container.container-body
            el-aside.aside(width='200px') 
              Sidebar(:menu-type="sidebarType")
            el-main 
              .container-main 
                router-view(:config-all="configAll")
</template>

<script>
import Header from './components/Header.vue'
import Sidebar from './components/Sidebar.vue'
import Http from '@/api/menu'
import UserIO from '@/api/userInfo';
import qs from 'qs';
// import http from '@utils/http'

export default {
  name: 'App',
  components: {
    Header,
    Sidebar,
  },

  data() {
    return {
      sidebarType: '',
      configAll: {},
      isLogin: false,
      userinfo: {},
    };
  },

  created() {
    this.getUserData();
  },

  mounted() {
    this.handleMenu();
  },

  methods: {
    displayType(sideType) {
      this.sidebarType = sideType;
      
    },

    handleMenu() {
      Http.getData().then(res => {
        this.configAll = res.body;
        console.log('res:', res, this.configAll)
      })
    },

    getUserData() {
      UserIO.getData({needLogin: false}).then(res => {
        if (res.code === 0) {
          
          let data = res.body || {};
          this.isLogin = true;
          this.userName = data.user_info.username || '';
          this.userinfo = data.user_info;
          window.localStorage.setItem('M_STORAGE_USER_INFO', qs.stringify(data.user_info))
        } else {
          this.isLogin = false;
          this.goLogin();
        }
      }, () => {
        this.isLogin = false;
        this.goLogin();
        // this.$router.push({name: 'Login'});
      });
    },

    goLogin() {
      if (this.$route.name !== 'Login') {
        this.$router.push({name: 'Login'});
      }
    }
  },

  watch: {
    ['$route.name'](val) {
      this.sidebarType = val === 'Video' ? 'video' : 'system';
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: '微软雅黑', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: #eff0f4;
}

#container {
  .container-inner {
    width: 100%;
  }
  .header-wrap {
    display: flex;
  }

  .site-title {
    background-image: url('./assets/logo.png');
    background-repeat: no-repeat;
    background-size: 27px 27px;
    background-position: 52px 16px;
    padding-left: 20px;
    width: 199px;
    background-color: #545c64;
    color: #fff;
    font-size: 16px;
    height: 60px;
    
  }

  .container-body {
    margin-top: 20px;
    padding: 0 20px;
  }

  .container-main {
    margin-left: 10px; 
    font-size: 13px;
  }

  .header-out {
    flex: 1;
    background-color: #545c64;
    padding-left: 100px;
  }
  .el-header {
    padding: 0;
  }
  .el-main {
    padding: 0
  }
}



.el-header, .el-footer {
    background-color: #B3C0D1;
    color: #333;
    text-align: center;
    line-height: 60px;
  }
  
  .el-aside {
    background-color: #FFF;
    color: #333;
    line-height: 200px;
    
  }
  
  
  
  body > .el-container {
    margin-bottom: 40px;
  }
  
  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }
  
  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  }
  


</style>
